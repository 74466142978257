import React from "react";
import { graphql } from "gatsby";
import { Grid, Image } from "semantic-ui-react";
import Helmet from "react-helmet";
import Markdown from "markdown-to-jsx";
import {
  PageLayout,
  CardNavigation,
  HeroElement,
  About,
  ImageCollection,
  Reviews,
  Contact,
} from "@fishrmn/fishrmn-components";

import Layout from "../components/Layout";
import { getPageHeroImages, getPageGalleryImages } from "../utils/images";

export default class extends React.PureComponent {
  render() {
    const data = this.props.data.allFisherman.edges[0].node;
    const pageData = this.props.data.fishermanWebsitePage;
    const pageComponents = (pageData && pageData.components) || [];

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Home</title>
          <meta name="description" content={pageData.description} />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={""}
              tagline={
                <>
                  <h1 style={{ color: "#fff" }}>{data.businessName}</h1>
                  <Image
                    centered
                    src="https://fisherman.gumlet.io/public/beaconhillbarbershop/frame.png"
                    size="small"
                  />
                </>
              }
              images={getPageHeroImages(pageComponents)}
              showMap={false}
              ctas={[
                {
                  text: "Book Appointment",
                  link:
                    "https://booking.appheaven.us/book-beaconhill",
                  internal: false,
                  className: "",
                },
              ]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={true}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <About
                content={<Markdown>{data.aboutMarkdown}</Markdown>}
                header={`Welcome to ${data.businessName}`}
                centerContent={true}
                headerAs={"h1"}
                withContainer={true}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <ImageCollection
                as={"gallery"}
                centered={true}
                cutUnevenRows={false}
                disable={"none"}
                header={"Photos"}
                images={getPageGalleryImages(pageComponents)}
                numberOfRowDisplayItems={3}
              />
            </Grid.Column>
          </Grid>
          {/* <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"left"}
          >
            <Grid.Column width={16}>
              <Reviews
                reviews={data.reviews}
                as={"verticalItem"}
                buttonBasic={false}
                buttonSize={"medium"}
                cardInlinePhoto={"left"}
                centerHeader={true}
                header={"Reviews"}
                imageCircular={false}
                imageSize={"small"}
                itemButtonFloat={"left"}
                itemCenterContent={false}
                itemsDivided={false}
                itemsPerRow={3}
                linkAs={"none"}
                onlyEvenRows={true}
                showImage={true}
                verticalItemAlign={"center"}
              />
            </Grid.Column>
          </Grid> */}
          <Grid stackable className="component-section-container">
            <Grid.Column width={16}>
              <Contact
                phoneNumber={data.phone}
                email={data.email}
                address={{
                  street: data.address.street,
                  city: data.address.city,
                  state: data.address.state,
                  zip: data.address.zip,
                }}
                hours={data.hours}
                as={"text"}
                buttonColor={"secondary"}
                header={"Contact"}
                image={""}
                showMap={true}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
          aboutMarkdown
          phone
          email
          address {
            street
            city
            state
            zip
          }
          hours {
            open
            close
            day
          }
        }
      }
    }
    fishermanWebsitePage(pageType: { eq: "Home" }) {
      description
      components {
        fastId
        order
        files {
          file
        }
      }
    }
  }
`;
